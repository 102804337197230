<template>
  <div class="update-import-trade-in">
    <KTCodePreview v-bind:title="'Thông tin phiếu thu cũ'">
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col lg="9" md="9" sm="12">
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Mã phiếu:</span>
                  </template>
                  <p>{{ codeStockBill }}</p>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Ngày tạo:</span>
                  </template>
                  <p>{{ dateCreate }}</p>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Ngày nhập kho:</span>
                    <span class="text-danger">*</span>
                  </template>
                  <p>{{ $v.form.dateImport.$model }}</p>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group label="Tổng tiền:">
                  <p>{{ convertPrice(totalPrice) }}</p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Kho:</span>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    class="mt-2 select-style"
                    v-model="$v.form.inventorySelected.$model"
                    :options="listInventories"
                    size="sm"
                    disabled
                    :state="validateState('inventorySelected')"
                    value-field="id"
                    text-field="name"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <i
                  class="fa fa-download text-primary cursor-pointer"
                  aria-hidden="true"
                  @click="openFileModal()"
                >
                  Quản lý tệp</i
                >
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Khách hàng:</span>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model="searchProvider"
                    size="sm"
                    placeholder="Chọn khách hàng"
                    class="input-style text-left mt-2"
                    disabled
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Vui lòng chọn nhà cung cấp</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Họ tên KH: </label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="customerName"
                    placeholder="Họ tên khách hàng"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Địa chỉ:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="customerAddress"
                    placeholder="Địa chỉ khách hàng"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Căn cước/chứng minh:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="customerIdentityNo"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="9" md="9" sm="12">
                <b-form-group>
                  <template>
                    <span>Nội dung:</span>
                  </template>
                  <b-form-textarea
                    size="sm"
                    v-model="description"
                    :placeholder="'Thêm nội dung...'"
                    :rows="3"
                    :max-rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Người nhập:</span>
                  </template>
                  <p>{{ createBy }}</p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-select
                  class="select-style"
                  v-model="selectTypeSearch"
                  :options="listTypeSearch"
                  size="sm"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <Autosuggest
                  :model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  placeholder="tên sản phẩm"
                  :limit="200"
                  @select="onSelectedProduct"
                  @change="onInputChangProduct"
                  suggestionName="productName"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col lg="3" md="3" sm="12">
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-form-group>
                  <label>Tiền mặt</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="cashAmount"
                    required
                    placeholder="Nhập số tiền mặt"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-form-group>
                  <label>Tài khoản tiền mặt:</label>
                  <Autosuggest
                    :model="handleSearchCash"
                    :suggestions="filteredOptionsCash"
                    :placeholder="'tài khoản tiền mặt'"
                    :limit="200"
                    @select="onSelectedCash"
                    @change="onInputChangeCash"
                    :disabled="!filteredOptionsCash.length"
                    suggestionName="suggestionName"
                  />
                  <b-form-invalid-feedback
                    :state="isValidCash"
                    id="input-cash-live-feedback"
                    >Vui lòng chọn tài khoản tiền mặt</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-form-group>
                  <label>Tiền chuyển khoản</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="transferAmount"
                    placeholder="Nhập số tiền"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-form-group>
                  <label>Tài khoản chuyển khoản:</label>
                  <Autosuggest
                    :model="searchTransfer"
                    :suggestions="filteredOptionsTransfer"
                    :placeholder="'tài khoản chuyển khoản'"
                    :limit="200"
                    @select="onSelectedTransfer"
                    @change="onInputChangeTransfer"
                    suggestionName="suggestionName"
                  />
                  <b-form-invalid-feedback
                    :state="isValidTransfer"
                    id="input-transfer-live-feedback"
                    >Vui lòng chọn tài khoản chuyển
                    khoản</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table
          class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          "
        >
          <thead>
            <tr>
              <th scope="col" class="title-center" style="width: 15%">
                Tên sản phẩm
              </th>
              <th scope="col" class="title-center" style="width: 5%">Tồn</th>
              <th scope="col" class="title-center" style="width: 20%">IMEI</th>
              <th scope="col" class="title-center" style="width: 10%">
                Số lượng
              </th>
              <th scope="col" class="title-center" style="width: 18%">
                Đơn giá
              </th>
              <th scope="col" class="title-center" style="width: 14%">
                Thành tiền
              </th>
              <th scope="col" class="title-center" style="width: 13%">
                Chiết khấu
              </th>
              <th scope="col" class="title-center" style="width: 5%"></th>
            </tr>
          </thead>

          <tbody v-for="item in listProductStock" :key="item.id">
            <ImportStock
              v-bind:productItem="item"
              v-bind:status="status"
              v-bind:editState="true"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
            />
          </tbody>
          <tr>
            <td :colspan="3" class="tdTextAlignPrice">Tổng</td>
            <td class="tdTextAlignPrice">
              {{ convertPrice(totalQuantity) }}
            </td>
            <td></td>
            <td class="tdTextAlignPrice">{{ convertPrice(totalPrice) }}</td>
            <td class="tdTextAlignPrice">
              {{ convertPrice(totalDiscount) }}
            </td>
            <td></td>
          </tr>
        </table>

        <hr class="hr-text" data-content="Bút Toán" style="font-weight: 600" />
        <b-row v-if="codeStockBill !== ''">
          <b-col md="12" lg="12" sm="12">
            <StockSlipTransactions :stockCode="codeStockBill" />
          </b-col>
        </b-row>
        <b-modal
          ref="file-modal"
          size="lg"
          hide-footer
          title="Chỉnh sửa tệp"
          id="file-modal"
        >
          <b-row>
            <b-col cols="12">
              <UploadFile :id="relatedStockCode" :entity="'evaluation-trade'" :isAllowUpload="false"/>
            </b-col>
          </b-row>
        </b-modal>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSubmit"
          >Lưu</b-button
        >
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 100px"
          variant="primary"
          size="sm"
          @click="exportExcel"
          >Xuất Excel</b-button
        >
        <router-link to="/stocks" tag="button">
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy</b-button
          >
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ImportStock from '@/view/components/ImportStock';
import StockSlipTransactions from '@/view/components/StockSlipTransactions';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { cmdUrl } from '@/utils/apiUrl';
import _ from 'lodash';
import { currencyMask, unMaskPrice, convertPrice } from '@/utils/common';
import axios from 'axios';
import { BASE_URL, TIME_TRIGGER } from '@/utils/constants';
import decounce from 'debounce';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { getToastInstance } from '@/utils/toastHelper';
import UploadFile from '@/view/modules/upload/Upload';

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        inventorySelected: null,
        providerSelected: null,
        dateImport: '',
      },
      relatedStockCode: null,
      isNew: true,
      idStock: '',
      codeStockBill: '',
      dateCreate: '',
      listInventories: [],
      description: '',
      createBy: '',
      status: 1,
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      editState: false,
      selectedClassProduct: null,
      cashAmount: 0,
      cashAccountCode: '',
      transferAmount: 0,
      transferAccountCode: '',
      filterModel: {
        cashAccountants: [],
        transferAccountants: [],
      },
      formater: {
        currency: currencyMask,
      },
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      filteredOptionsCash: [],
      optionsCash: [
        {
          data: [],
        },
      ],
      searchCash: '',
      filteredOptionsTransfer: [],
      optionsTransfer: [
        {
          data: [],
        },
      ],
      searchTransfer: '',
      isSearching: false,
      stockSlipType: null,
      customerName: '',
      customerAddress: '',
      isValidCash: true,
      isValidTransfer: true,
      customerIdentityNo: null,
      entityUploadId: null,
    };
  },
  validations: {
    form: {
      inventorySelected: { required },
      providerSelected: { required },
      dateImport: { required },
    },
  },
  components: {
    KTCodePreview,
    ImportStock,
    StockSlipTransactions,
    Autosuggest,
    UploadFile,
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
    handleSearchCash() {
      return this.filteredOptionsCash.length > 0
        ? this.searchCash
        : 'Không có thông tin loại tài khoản tiền mặt';
    },
  },
  created() {
    this.fetchAccountants('cash');
    this.fetchAccountants('transfer');
    this.fetchStore();
    this.entityUploadId = this.$route.query.id;
    this.getStockById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu thu cũ', route: '/import-trade-in' },
      { title: 'Cập nhật phiếu thu cũ' },
    ]);
  },
  methods: {
    openFileModal() {
      this.$refs['file-modal'].show();
    },
    convertPrice,
    ...getToastInstance(),
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      this.searchProduct = option.item.productName;
      let quantity = 0;
      if (this.selectedClassProduct.productType === 1) {
        quantity = 1;
      }
      const pro = {
        id: this.$uuid.v4(),
        name: this.selectedClassProduct.productName,
        totalQuantityInStock:
          this.selectedClassProduct.quantityInStock != null
            ? this.selectedClassProduct.quantityInStock
            : 0,
        IMEI: '',
        proId: this.selectedClassProduct.productId,
        price: this.selectedClassProduct.originalPrice,
        quantity: quantity,
        totalPrice: quantity * this.selectedClassProduct.originalPrice,
        productType: this.selectedClassProduct.productType,
        discount: 0,
      };
      if (this.selectedClassProduct.productType === 3) {
        this.makeToastFailure('Không hỗ trợ nhập kho sản phẩm combo');
      } else {
        this.listProductStock.push(pro);
      }
      this.searchProduct = '';
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    getStockById() {
      let id = this.$route.query.id;
      ApiService.get(`stocks/${id}`)
        .then(({ data }) => {
          let dataRes = data.data;
          this.codeStockBill = dataRes.code;
          this.dateCreate = moment(String(dataRes.createdAt)).format(
            'HH:mm DD/MM/YYYY'
          );
          this.$v.form.dateImport.$model = moment(
            String(dataRes.importDate)
          ).format('DD-MM-YYYY');
          this.$v.form.inventorySelected.$model = dataRes.storeId;
          this.$v.form.providerSelected.$model = dataRes.providerId;
          this.searchProvider = dataRes.customerPhone;
          this.customerName = dataRes.providerName;
          this.customerAddress = dataRes.address;
          this.customerIdentityNo = dataRes.customerIdentityNo;
          this.totalPrice = dataRes.totalAmount;
          this.description = dataRes.description;
          this.createBy = dataRes.createdBy;
          this.status = dataRes.status;
          this.idStock = dataRes.id;
          this.cashAmount = dataRes.cashAmount;
          this.cashAccountCode = dataRes.cashAccountCode;
          this.searchCash = dataRes.cashAccName;
          this.transferAmount = dataRes.transferAmount;
          this.transferAccountCode = dataRes.transferAccountCode;
          this.searchTransfer = dataRes.transferAccName;
          this.stockSlipType = dataRes.type;
          this.relatedStockCode = dataRes.relatedStockCode;
          this.listProductStock = dataRes.listDetail.map((element) => {
            let listImei = element.productImei.split(',');
            let stt = 1;
            let formatImei = '';
            listImei.forEach((element, index) => {
              formatImei += stt + ')  ' + element;
              if (index !== listImei.length - 1) {
                formatImei += ',';
              }
              stt++;
            });
            return {
              id: element.id,
              name: element.productName,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
              productType: element.productType,
              formatImei: formatImei.replace(/[,]/g, '\n').replace(/[ ]/g, ''),
              totalQuantityInStock:
                element.totalQuantityInStock != null
                  ? element.totalQuantityInStock
                  : 0,
              discount: element.discount,
            };
          });
          this.fetchAccountants('cash');
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    fetchStore() {
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.get(
        `productSearch?storeId=${this.inventorySelected}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          let nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = unMaskPrice(item.price);
          this.listProductStock[index].quantity = item.quantity;
          this.listProductStock[index].totalPrice = item.totalPrice;
          this.listProductStock[index].discount = unMaskPrice(item.discount);
        }
      });
    },
    onSubmit: async function () {
      this.isNew = false;
      this.isValidCash = this.validateCash();
      this.isValidTransfer = this.validateTransfer();
      if (!this.isValidCash || !this.isValidTransfer) {
        return this.makeToastFailure(
          'Vui lòng nhập thông tin thanh toán hợp lệ!'
        );
      }

      const listPro = this.listProductStock.map((element) => {
        return {
          id: element.id,
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
          discount: unMaskPrice(element.discount),
        };
      });
      const data = {
        id: this.idStock,
        storeId: this.$v.form.inventorySelected.$model,
        providerId: this.$v.form.providerSelected.$model,
        description: this.description,
        importDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD'
            )
          : '',
        listProduct: listPro,
        cashAmount: unMaskPrice(this.cashAmount),
        cashAccountCode: this.cashAccountCode,
        transferAmount: unMaskPrice(this.transferAmount),
        transferAccountCode: this.transferAccountCode,
      };
      ApiService.put('stock-slips/import-tradein', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.$router.push({
              path: '/import-trade-in',
            });
          } else {
            this.makeToastFailure(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    fetchAccountants: async function (mode) {
      this.optionsCash[0].data = [];
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.$v.form.inventorySelected.$model !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type: mode === 'cash' ? '1' : '2',
          storeId: this.$v.form.inventorySelected.$model,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          let element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash[0].data.push(element);
          } else if (mode === 'transfer') {
            this.optionsTransfer[0].data.push(element);
          }
        });
        this.filteredOptionsCash = [...this.optionsCash[0].data];
        this.filteredOptionsTransfer = [...this.optionsTransfer[0].data];
      });
    },
    exportExcel: async function () {
      ApiService.setHeader();
      let baseUrl = BASE_URL;
      let url = `${baseUrl}stocks/export-excel/${this.idStock}?type=1`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        // let fileName = 'Hóa đơn nhập NCC';
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    onSelectedCash(option) {
      this.searchCash = option.item.name;
      this.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [...filteredData];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [...filteredData];
    },
    debounceInputProduct: decounce(function () {
      this.nextSearchProduct();
    }, TIME_TRIGGER),
    nextSearchProduct() {
      if (!this.isSearching) {
        const textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    },
    validateCash() {
      const cashAmount = unMaskPrice(this.cashAmount);
      this.searchCash = this.searchCash ? this.searchCash.trim() : '';
      if (!this.searchCash) {
        this.cashAccountCode = '';
      }
      if (parseInt(cashAmount) > 0 && !this.cashAccountCode) {
        return false;
      }
      return true;
    },
    validateTransfer() {
      const transferAmount = unMaskPrice(this.transferAmount);
      this.searchTransfer = this.searchTransfer
        ? this.searchTransfer.trim()
        : '';
      if (!this.searchTransfer) {
        this.transferAccountCode = '';
      }
      if (parseInt(transferAmount) > 0 && !this.transferAccountCode) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';

.update-import-trade-in {
  .input-group-append {
    cursor: pointer;
    padding-top: 5.5px;
  }

  .input-group-append:hover i {
    color: #3699ff;
  }

  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
    font-weight: 600;
  }
  .title-center {
    text-align: center;
  }
}
</style>
